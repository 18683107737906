<template>
  <div>
    
            <div class="card mb-5 mb-xl-12">
                <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                    <div class="card-title m-0">
                        <h3 class="fw-bolder m-0">Profil Bilgileri</h3>
                    </div>
                </div>

                <div id="kt_account_settings_profile_details" class="collapse show">
                    <form id="kt_account_profile_details_form" class="form">
                        <div class="card-body border-top p-9">

                            <div class="row mb-6">
                                <label class="col-lg-4 col-form-label required fw-bold fs-6">Adı Soyadı</label>
                                <div class="col-lg-8">
                                    <div class="row">
                                        <div class="col-lg-4 fv-row">
                                            <input type="text" name="name" v-model="consProfile.name" id="name" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Adı" />
                                        </div>

                                        <div class="col-lg-4 fv-row">
                                            <input type="text" name="surname" v-model="consProfile.surname" class="form-control form-control-lg form-control-solid" placeholder="Soyadı" />
                                        </div>

                                        <div class="col-lg-4 fv-row">
                                            <input type="text" name="title" v-model="consProfile.title" class="form-control form-control-lg form-control-solid" placeholder="Ünvanı" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-6">
                                <label class="col-lg-4 col-form-label required fw-bold fs-6">E-posta / Telefon</label>
                                <div class="col-lg-8">
                                    <div class="row">
                                        <div class="col-lg-6 fv-row">
                                            <input type="email" name="email" v-model="consProfile.email" id="email" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="E-posta adresi" />
                                        </div>

                                        <div class="col-lg-6 fv-row">
                                            <input type="tel" v-model="consProfile.phone" v-maska="'(###) ### ## ##'" class="form-control form-control-lg form-control-solid" placeholder="Telefon Numarası">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-6">
                                <label class="col-lg-4 col-form-label required fw-bold fs-6">Cinsiyet / Doğum Tarihi / Şehir</label>
                                <div class="col-lg-8">
                                    <div class="row">
                                        <div class="col-lg-3 fv-row">
                                            <div class="row d-flex" style="height:100%">
                                                <div class="col-lg-6 fv-row d-flex align-items-center">
                                                    <label class="form-check form-check-custom form-check-solid inline">
                                                        <input class="form-check-input" v-model="consProfile.gender" type="radio" value="M">
                                                        <span class="form-check-label">Erkek</span>
                                                    </label>
                                                </div>
                                                <div class="col-lg-6 fv-row d-flex align-items-center">
                                                    <label class="form-check form-check-custom form-check-solid inline">
                                                        <input class="form-check-input" v-model="consProfile.gender" type="radio" value="F">
                                                        <span class="form-check-label">Kadın</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 fv-row">                                            
                                            <input type="date" v-model="consProfile.birthday" id="birthday" placeholder="Doğum Tarihi" class="form-control form-control-lg form-control-solid">
                                        </div>
                                        <div class="col-lg-5 fv-row">
                                            <input type="tel" v-model="consProfile.city" class="form-control form-control-lg form-control-solid" placeholder="Şehir" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-6" v-if="consultantTypes">
                                
                                    <label for="" class="col-lg-4 col-form-label fw-bold fs-6">Danışman Tipi</label>
                                    <div class="col-lg-4">
                                        <select name="consultantTypeId" class="form-select form-select-solid" id="consultantTypeId" v-model="consProfile.consultantTypeId">
                                            <option value="">Seçiniz</option>
                                            <option :value="item.consultantTypeId" v-for="item in consultantTypes" :key="item.consultantTypeId">{{item.consultantType}}</option>
                                        </select>
                                    </div>
                                
                            </div>

                            <div class="row mb-6">
                                <div class="col-lg-4 fv-row">
                                    <label class="col-lg-4 col-form-label fw-bold fs-6">HAKKINDA</label>
                                    <textarea name="bio" v-model="consProfile.bio" id="bio" cols="30" rows="10" class="form-control form-control-lg form-control-solid"></textarea>
                                </div>
                                <div class="col-lg-4 fv-row">
                                    <label class="col-lg-4 col-form-label fw-bold fs-6">SERTİFİKALAR</label>
                                    <textarea name="education" v-model="consProfile.education" id="education" cols="30" rows="10" class="form-control form-control-lg form-control-solid"></textarea>
                                </div>
                                <div class="col-lg-4 fv-row">
                                    <label class="col-lg-4 col-form-label fw-bold fs-6">UZMANLIKLAR</label>
                                    <textarea name="career" v-model="consProfile.career" id="career" cols="30" rows="10" class="form-control form-control-lg form-control-solid"></textarea>
                                </div>
                            </div>
                            <div class="row d-flex mb-6" v-if="consProfile.userId>0">
                                <label class="col-lg-4 col-form-label fw-bold fs-6">Şifre</label>
                                <div class="col-lg-6">
                                    
                                    <input type="text" name="password" class="form-control form-control-lg form-control-solid" v-model="consProfile.password">
                                </div>
                            </div>
                            <div class="row d-flex" style="height:100%">
                                <div class="col-lg-4 col-form-label fw-bold fs-6">Durum</div>
                                <div class="col-lg-6 fv-row d-flex align-items-center">
                                    <label class="form-check form-check-custom form-check-solid inline mr-10">
                                        <input class="form-check-input" name="status" :checked="consProfile.status=='AC'" v-model="consProfile.status" type="radio" value="AC">
                                        <span class="form-check-label">Aktif</span>
                                    </label>
                                    <label class="form-check form-check-custom form-check-solid inline">
                                        <input class="form-check-input" name="status" :checked="consProfile.status=='DA'" v-model="consProfile.status" type="radio" value="DA">
                                        <span class="form-check-label">Pasif</span>
                                    </label>
                                </div>
                            </div>

                        </div>

                        <div class="card-footer d-flex justify-content-end py-6 px-9">
                            <button type="submit" @click.prevent="save()" class="btn btn-primary" id="kt_account_profile_details_submit">Değişiklikleri Kaydet</button>
                        </div>
                    </form>
                </div>
            </div>
    </div>

</template>

<script>
import moment from 'moment';
import { defineComponent } from "vue";
import { maska } from 'maska'

import api from '@/core/services/ApiService'


export default defineComponent({
  name: "ConsultantDetail",
  props:['profile'],
  
  directives: { maska },
  data(){
      return {
          consProfile : this.profile,
          consultantTypes:null
      }
  },
  methods:{
    
    moment(date){
        return moment(date)
    },
	save(){
        this.$emit("saved")
	},
    
    
  },
  mounted(){
      if(this.$route.query.type==6 || this.profile.userTypeId==6){
            api.get('/User/ListDoctorType/JJ').then((res)=>{
                this.consultantTypes = res.data;
            });
        }
      this.consProfile.birthday = moment(this.consProfile.birthday).format('YYYY-MM-DD');
  }
});

</script>

