<template>
  <div>
      <edit-consultant @saved="save()" :profile="profile" :mode="edit" />
  </div>
</template>

<script>
import EditConsultant from '@/components/modals/forms/EditConsultant.vue'
import api from '@/core/services/ApiService'
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
    props:['profile'],
    components:{
        EditConsultant
    },
    methods:{
        save(){
            let profile = this.profile;
            if(this.profile.status===true){
                profile.status='AC'
            }

            if(this.profile.status===false){
                profile.status='DA'
            }

            profile.phone = profile.phone.replace(/[^0-9]/g,"");

            api.post('/User/UpdateUser',profile).then((res)=>{
                Swal.fire({
                    text:'Danışman Bilgileri Güncellendi',
                    icon:'success',
                });
                
            }).catch((err)=>{
                console.log(err);
            })
        }
    }

}
</script>

<style>

</style>